<template>
  <div class="data"
      v-loading='data_running'>
    <content-title
      :title="$t('message.statistics')"
    ></content-title>

    <!-- 数据列表 -->
    <div class="data_list_box" v-if="dataArr.length > 0">
      <div class="data_title flexCenter-ai-center">
        <span style="width: 25%" class="flexCenter-column">{{$t('message.date1')}}</span>
        <span style="width: 25%" class="flexCenter-column">{{$t('message.newUsers')}}</span>
        <span style="width: 25%" class="flexCenter-column">{{$t('message.amount')}}</span>
        <span style="width: 25%" class="flexCenter-column">{{$t('message.commission')}}</span>
      </div>
      <div class="data_list">
        <div class="data_item flexCenter-ai-center" v-for="item in dataArr" :key="item.id">
          <span class="flexCenter-column" style="width: 25%">{{ item.date }}</span>
          <span class="flexCenter-column" style="width: 25%">{{ item.incr_user_num }}</span>
          <span class="flexCenter-column" style="width: 25%">{{ item.charge_amount }}</span>
          <span class="flexCenter-column" style="width: 25%">{{ item.commission }}</span>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="sizeChange"
          :hide-on-single-page="true"
          :total="count"
          :page-size="data.limit"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty flexCenter flex-column" v-if="is_data">
      <img src="@/assets/images/empty.png" alt="" />
      <span>{{ $t("message.noMoreData") }}</span>
    </div>

  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { dataList } from '@/apis/api'
export default {
  name: 'dataPage',
  data () {
    return {
      count: 0,
      data: {
        page: 1,
        limit: 20,
      },
      dataArr: [], // 数据列表
      data_running: false,
      is_data: false, // 是否加载无数据布局
    }
  },
  created () {
    this.setDataList()
  },
  methods: {
    // 分页
    sizeChange(v) {
      this.data.page = v;
      this.setDataList()
    },
    // 获取数据列表
    async setDataList() {
      this.data_running = true
      let params = {
        page: this.data.page,
        limit: this.data.limit,
      }
      let res = await dataList(params)
      if (res.code === 1) {
        this.count = res.count
        this.dataArr = res.data
      }
      this.data_running = false
      res.data.length > 0 ? this.is_data = false : this.is_data = true
      console.log(res)
    }
  },
  components: {
    contentTitle,
  },
}
</script>
<style lang="scss" scoped>
.data {
  .data_list_box {
    margin-top: 30px;
    border-radius: 10px;
    background: #ffffff;
    // height: 660px;
    .data_title {
      height: 60px;
      > span {
        text-align: left;
        // padding-left: 45px;
        color: #15161a;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .data_list {
      line-height: 30px;
      .data_item {
        height: 54px;
        background-color: #fff;
        >span {
          color: #32343A;
          // padding-left: 45px;
        }
      }
      .data_item:nth-child(odd) {
        background-color: #f9f9f9;
      }
      .data_item:hover {
        background-color: #F3F6FF;
      }
    }

    .pagination {
      padding-bottom: 40px;
      margin-top: 30px;
    }
  }
  
  .empty {
    height: 500px;
    border-radius: 10px;
    background: #ffffff;
    margin-top: 30px;
    img {
      width: 140px;
    }
    span {
      color: #9497a1;
      font-size: 14px;
    }
  }
}
</style>