<template>
  <div class="contentTitle">
    <div class="title" @click="back">{{title}}</div>
    <div class="min_title">{{min_title}}</div>
    <div class="next_page" @click="nextPage()">{{next_page}}</div>
  </div>
</template>
<script>
export default {
  name: 'contentTitle',
  props: {
    title: '',
    min_title: '',
    next_page: ''
  },
  methods: {
    back() {
      this.$emit('back');
    },
    nextPage() {
      this.$emit('nextPage');
    }
  }
}
</script>
<style lang="scss" scoped>
.contentTitle {
  margin-bottom: 28px;
  display: flex;
  align-items: flex-end;
  .title {
    font-size: 22px;
    font-weight: 600;
    color: #15161A;
    cursor: pointer;
  }
  .min_title {
    font-size: 14px;
    color: #65676E;
    font-weight: 500;
    margin-left: 22px;
    cursor: pointer;
  }
  .next_page {
    color: #2F6AFA;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    // margin-left: 20px;
    padding-bottom: 2px;
    cursor:pointer;
  }
  .next_page:hover{
    color: #1954E1;
  }
}
</style>
